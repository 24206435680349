import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react'

import {
  AddAfter,
  Chain,
  Checkbox,
  Cross,
  PenOutlined,
  PlusCircle,
  Tooltip,
  Trash,
  VerticalThreeDots,
} from '@gmini/ui-kit'

import { useContextMenu } from '@gmini/common'

import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'

import { toastr } from 'react-redux-toastr'

import { AddFolderIcon } from '../icons/AddFolderIcon'

import { useAppDispatch, useAppSelector } from '../../store/store'

import {
  addAttributeGroup,
  addAttributeValue,
  deleteAttribute,
  deleteAttributeGroup,
  deleteAttributeValue,
  getAttribute,
  getProjectModuleAttributes,
} from '../../store/attributes/actions'

import {
  Attribute,
  AttributeGroup,
  AttributeGroupWithChildren,
  AttributeInstances,
  AttributeValue,
} from '../../store/attributes/types'

import { MoveAttributeValueModal } from '../MoveAttributeValueModal/MoveAttributeValueModal'

import { DeletePopup } from '../DeletePopup/DeletePopup'

import { Text } from '../DeletePopup/DeletePopup.styled'

import { EditOrAddAttributeModal } from '../EditOrAddAttributeModal/EditOrAddAttributeModal'

import { UnlinkIcon } from '../icons/UnlinkIcon'

import { LinkToProjectIcon } from '../icons/LinkToProjectIcon'

import { LinkAttributeValuesToProjectModal } from '../LinkAttributeValuesToProjectModal/LinkAttributeValuesToProjectModal'

import { projectModuleValueSplitter } from '../../store/attributes/attributes-slice'

import { UnlinkAttributeValuesToProjectModal } from '../UnlinkAttributeValuesToProjectModal/UnlinkAttributeValuesToProjectModal'

import { AddAttributeValuesToProjectAfterActionModal } from '../AddAttributeValuesToProjectAfterActionModal/AddAttributeValuesToProjectAfterActionModal'

import { SnackbarWrapper } from '../toastrOptions/ToastrSnackbar.styled'

import {
  AddAttributeValueButton,
  AttributeTabContentBlock,
  AttributeTabList,
  AttributeTabListHeader,
  AttributeTabTabWrapper,
  Badge,
  DeletePopupTitle,
  ListHeaderButtonContainer,
  ListHeaderHeading,
  StyledIconButton,
  TabRightSideWrapper,
} from './AttributeTab.styled'
import { AttributeValueGroupItem } from './AttributeValueGroupItem'
import { AttributeValueItem } from './AttributeValueItem'
import { StyledAttributeInput } from './AttributeValueGroupItem.styled'
import { checkNodeAccordance } from './utils'
import { AttributesInfo } from './AttributesInfo'
import { AttributeProjectItem } from './AttributeProjectItem'

type AttributeTabProps = {
  attribute: Attribute
  setSelectedAttribute: Dispatch<SetStateAction<Attribute | null>>
}

type tabStateType = {
  attributeGroupToDelete: AttributeGroup | null
  addAttributeValuesToProjectAfterActionAction: {
    action: () => Promise<unknown>
    groupId: number
    actionType: 'create' | 'move'
  } | null
  valueToEdit: AttributeValue | AttributeGroupWithChildren | null
  isAttributeValue: boolean
  isInputOpen: boolean
  isDeleteAttributeOpen: boolean
  isEditAttributeOpen: boolean
  isMoveAttributeValuesOpen: boolean
  isDeleteAttributeValuesOpen: boolean
  isLinkAttributeValuesOpen: boolean
  isUnlinkAttributeValuesOpen: boolean
}

type BooleanAction = {
  type:
    | 'setIsAttributeValue'
    | 'setIsInputOpen'
    | 'setIsDeleteAttributeOpen'
    | 'setIsEditAttributeOpen'
    | 'setIsLinkAttributeValuesOpen'
    | 'setIsUnlinkAttributeValuesOpen'
    | 'setIsMoveAttributeValuesOpen'
    | 'setIsDeleteAttributeValuesOpen'
  payload: boolean
}

type GroupAction = {
  type: 'setAttributeGroupToDelete'
  payload: AttributeGroupWithChildren | null
}

type ValueGroupAction = {
  type: 'setValueToEdit'
  payload: AttributeValue | AttributeGroupWithChildren | null
}

const initTabState = {
  attributeValueToMove: null,
  attributeValueToDelete: null,
  attributeGroupToDelete: null,
  addAttributeValuesToProjectAfterActionAction: null,
  valueToEdit: null,
  isAttributeValue: false,
  isInputOpen: false,
  isDeleteAttributeOpen: false,
  isEditAttributeOpen: false,
  isDeleteAttributeValuesOpen: false,
  isMoveAttributeValuesOpen: false,
  isLinkAttributeValuesOpen: false,
  isUnlinkAttributeValuesOpen: false,
}

const reducer = (
  state: tabStateType,
  action:
    | BooleanAction
    | GroupAction
    | ValueGroupAction
    | {
        type: 'setAddAttributeValuesToProjectAfterActionAction'
        payload: {
          action: () => Promise<unknown>
          groupId: number
          actionType: 'create' | 'move'
        } | null
      },
) => {
  const { type, payload } = action
  switch (type) {
    case 'setIsMoveAttributeValuesOpen':
      return { ...state, isMoveAttributeValuesOpen: payload }
    case 'setIsDeleteAttributeValuesOpen':
      return { ...state, isDeleteAttributeValuesOpen: payload }
    case 'setAttributeGroupToDelete':
      return { ...state, attributeGroupToDelete: payload }
    case 'setValueToEdit':
      return { ...state, valueToEdit: payload }
    case 'setIsAttributeValue':
      return { ...state, isAttributeValue: payload }
    case 'setIsInputOpen':
      return { ...state, isInputOpen: payload }
    case 'setIsLinkAttributeValuesOpen':
      return { ...state, isLinkAttributeValuesOpen: payload }
    case 'setIsUnlinkAttributeValuesOpen':
      return { ...state, isUnlinkAttributeValuesOpen: payload }
    case 'setAddAttributeValuesToProjectAfterActionAction':
      return {
        ...state,
        addAttributeValuesToProjectAfterActionAction: payload,
      }
    case 'setIsDeleteAttributeOpen':
      return { ...state, isDeleteAttributeOpen: payload }
    case 'setIsEditAttributeOpen':
      return { ...state, isEditAttributeOpen: payload }
    default:
      throw new Error(
        `Unhandled expression in switch: ${(action as any)?.type}`,
      )
  }
}

export const AttributeTab = ({
  attribute,
  setSelectedAttribute,
}: AttributeTabProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [tabState, dispatchTabAction] = useReducer(reducer, initTabState)
  const [checkedAttributeValuesMap, setCheckedAttributeValuesMap] = useState<{
    [x: number]: boolean
  }>({})

  const [checkedProjectModuleValuesMap, setCheckedProjectModuleValuesMap] =
    useState<{ [x: string]: boolean }>({})

  const {
    attributeGroupToDelete,
    addAttributeValuesToProjectAfterActionAction,
    isAttributeValue,
    isDeleteAttributeOpen,
    isEditAttributeOpen,
    isLinkAttributeValuesOpen,
    isUnlinkAttributeValuesOpen,
    isDeleteAttributeValuesOpen,
    isMoveAttributeValuesOpen,
    isInputOpen,
    valueToEdit,
  } = tabState

  const { id: attributeId, name } = attribute

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isInputOpen) {
      inputRef.current?.focus()
    }
  }, [isInputOpen])

  useEffect(() => {
    dispatch(getAttribute({ attributeId }))
    dispatch(getProjectModuleAttributes({ attributeId }))
    setCheckedAttributeValuesMap({})
  }, [dispatch, attributeId])

  const {
    children,
    childrenFlatMap,
    childrenSortIndexesMap,
    pending: attributeValuesPending,
    projectModuleValues,
    projectModuleValuesMap,
  } = useAppSelector(state => state.currentAttribute)

  useEffect(() => {
    setCheckedProjectModuleValuesMap(projectModuleValuesMap)
  }, [projectModuleValuesMap])

  const groupList = useMemo(
    () =>
      children.filter(
        (v): v is AttributeGroupWithChildren =>
          v.type === AttributeInstances.GROUP,
      ),
    [children],
  )

  const valuesList = useMemo(
    () =>
      children.reduce(
        (acc: AttributeValue[], v) =>
          v.type === AttributeInstances.VALUE
            ? [...acc, v]
            : [...acc, ...v.children],
        [],
      ),
    [children],
  )

  const handleAddAttributeValueGroup = useCallback(
    (name: string) => {
      dispatch(addAttributeGroup({ name, attributeId }))
    },
    [dispatch, attributeId],
  )

  const handleAddAttributeValue = useCallback(
    (name: string) => {
      dispatch(addAttributeValue({ name, attributeId, parentGroupId: 0 }))
    },
    [dispatch, attributeId],
  )

  const isCheckedProjectModuleValuesMapNotEmpty = useMemo(
    () => !!Object.values(checkedProjectModuleValuesMap).filter(Boolean).length,
    [checkedProjectModuleValuesMap],
  )

  const {
    ContextMenu: ListItemContextMenu,
    setCtxMenu: setListItemCtxMenu,
    ctxMenu: listItemCtxMenu,
  } = useContextMenu<{
    item: AttributeValue | AttributeGroupWithChildren
    event: MouseEvent
  }>([
    {
      title: 'Редактировать',
      dataTestId: 'attributeValuesListCtxMenuEdit',
      onClick: ({ item }) =>
        dispatchTabAction({ type: 'setValueToEdit', payload: item }),
      customIcon: <PenOutlined />,
    },
    {
      title: 'Привязать к проектам',
      dataTestId: 'attributeValuesListCtxMenuLink',
      show: ({ item }) =>
        item.type === AttributeInstances.GROUP
          ? !!item.children.length &&
            isCheckedProjectModuleValuesMapNotEmpty &&
            !!checkedAttributeValuesIdsList.length
          : isCheckedProjectModuleValuesMapNotEmpty &&
            !!checkedAttributeValuesIdsList.length,
      onClick: ({ item }) => {
        setCheckedAttributeValuesMap(
          item.type === AttributeInstances.GROUP
            ? item.children.reduce((acc, c) => ({ ...acc, [c.id]: true }), {})
            : { [item.id]: true },
        )
        dispatchTabAction({
          type: 'setIsLinkAttributeValuesOpen',
          payload: true,
        })
      },
      customIcon: <LinkToProjectIcon />,
    },
    {
      title: 'Переместить',
      dataTestId: 'attributeValuesListCtxMenuMove',
      show: ({ item: { type } }) => type === AttributeInstances.VALUE,
      onClick: ({ item }) => {
        if (item.type === AttributeInstances.VALUE) {
          setCheckedAttributeValuesMap({ [item.id]: true })
          dispatchTabAction({
            type: 'setIsMoveAttributeValuesOpen',
            payload: true,
          })
        }
      },
      customIcon: <AddAfter />,
    },
    {
      dataTestId: 'attributeValuesListCtxMenuDelete',
      title: 'Удалить',
      onClick: ({ item }) => {
        if (item.type === AttributeInstances.GROUP) {
          dispatchTabAction({
            type: 'setAttributeGroupToDelete',
            payload: item,
          })
          return
        }
        setCheckedAttributeValuesMap({ [item.id]: true })
        dispatchTabAction({
          type: 'setIsDeleteAttributeValuesOpen',
          payload: true,
        })
      },
      icon: Icon.DELETE,
    },
  ])

  const { ContextMenu: AttributeContextMenu, setCtxMenu: setAttributeCtxMenu } =
    useContextMenu<{
      item: Attribute
      event: MouseEvent
    }>([
      {
        title: 'Редактировать атрибут',
        dataTestId: 'attributeCtxMenuEdit',
        onClick: () =>
          dispatchTabAction({ type: 'setIsEditAttributeOpen', payload: true }),
        customIcon: <PenOutlined />,
      },
      {
        dataTestId: 'attributeCtxMenuDelete',
        title: 'Удалить атрибут',
        onClick: () =>
          dispatchTabAction({
            type: 'setIsDeleteAttributeOpen',
            payload: true,
          }),
        icon: Icon.DELETE,
      },
    ])

  const onListItemActionButtonClick = (
    e: MouseEvent,
    item: AttributeValue | AttributeGroupWithChildren,
  ) => {
    e.stopPropagation()
    e.preventDefault()
    setListItemCtxMenu({
      coords: { x: e.clientX, y: e.clientY },
      item: { item, event: e },
    })
  }

  const handleSubmitDelete = async (type: AttributeInstances, id?: number) => {
    if (type === AttributeInstances.VALUE) {
      await Promise.all(
        checkedAttributeValuesIdsList.map(id =>
          dispatch(
            deleteAttributeValue({
              attributeId,
              valueId: Number(id),
              noAttributeFetch: true,
            }),
          ),
        ),
      )
      await dispatch(getAttribute({ attributeId }))
      dispatchTabAction({
        type: 'setIsDeleteAttributeValuesOpen',
        payload: false,
      })
      toastr.info('', '', {
        icon: <div />,
        component: (
          <SnackbarWrapper>{`Значения атрибута успешно удалены.`}</SnackbarWrapper>
        ),
      })
      return
    }
    if (!id) {
      return
    }
    if (type === AttributeInstances.ATTRIBUTE) {
      dispatch(deleteAttribute({ attributeId: id, name }))
      dispatchTabAction({ type: 'setIsDeleteAttributeOpen', payload: false })
    } else if (type === AttributeInstances.GROUP) {
      dispatch(deleteAttributeGroup({ attributeId, name, groupId: id }))
      dispatchTabAction({ type: 'setAttributeGroupToDelete', payload: null })
    }
  }

  const checkedAttributeValuesIdsList = useMemo(
    () =>
      Object.keys(checkedAttributeValuesMap)
        .filter(key => checkedAttributeValuesMap[Number(key)])
        .sort((a, b) => childrenSortIndexesMap[a] - childrenSortIndexesMap[b]),
    [checkedAttributeValuesMap, childrenSortIndexesMap],
  )

  const checkedProjectModuleValuesMapLength = useMemo(
    () =>
      Object.keys(checkedProjectModuleValuesMap).filter(
        key =>
          checkedProjectModuleValuesMap[key] &&
          key.includes(projectModuleValueSplitter),
      ).length,
    [checkedProjectModuleValuesMap],
  )

  return (
    <AttributeTabTabWrapper>
      <ListItemContextMenu />
      <AttributeContextMenu />
      <AttributeTabContentBlock>
        <AttributeTabListHeader>
          <ListHeaderButtonContainer>
            <ListHeaderHeading>
              <Checkbox
                data-test-id='selectAllAttributeValuesCheckbox'
                checked={
                  !!checkedAttributeValuesIdsList.length &&
                  checkedAttributeValuesIdsList.length === valuesList.length
                }
                indeterminate={
                  !!checkedAttributeValuesIdsList.length &&
                  checkedAttributeValuesIdsList.length !== valuesList.length
                }
                onChange={() =>
                  setCheckedAttributeValuesMap(prev =>
                    !checkedAttributeValuesIdsList.length
                      ? valuesList.reduce(
                          (acc, v) => ({ ...acc, [v.id]: true }),
                          {},
                        )
                      : {},
                  )
                }
              />
              <span>Значения </span>
            </ListHeaderHeading>
            {!!checkedAttributeValuesIdsList.length && (
              <Badge>
                {checkedAttributeValuesIdsList.length}
                <Cross
                  data-test-id='deselectAllAttributeValuesBtn'
                  onClick={() => setCheckedAttributeValuesMap({})}
                />
              </Badge>
            )}
          </ListHeaderButtonContainer>
          <ListHeaderButtonContainer>
            {!checkedAttributeValuesIdsList.length && (
              <>
                <Tooltip title='Добавить группировку'>
                  <AddAttributeValueButton
                    data-test-id='attributeTabAddGroupBtn'
                    onClick={() => {
                      dispatchTabAction({
                        type: 'setIsAttributeValue',
                        payload: false,
                      })
                      dispatchTabAction({
                        type: 'setIsInputOpen',
                        payload: true,
                      })
                    }}
                  >
                    <AddFolderIcon />
                  </AddAttributeValueButton>
                </Tooltip>
                <AddAttributeValueButton
                  data-test-id='attributeTabAddValueBtn'
                  onClick={() => {
                    dispatchTabAction({
                      type: 'setIsAttributeValue',
                      payload: true,
                    })
                    dispatchTabAction({ type: 'setIsInputOpen', payload: true })
                  }}
                >
                  <PlusCircle color='#353B60' />
                  <span>Значение</span>
                </AddAttributeValueButton>
              </>
            )}
            {!!checkedAttributeValuesIdsList.length && (
              <>
                <Tooltip title='Редактировать значение атрибутов'>
                  <AddAttributeValueButton
                    data-test-id='attributeTabEditValueBtn'
                    onClick={() => {
                      const payload =
                        childrenFlatMap[
                          Number(checkedAttributeValuesIdsList[0])
                        ]

                      dispatchTabAction({ type: 'setValueToEdit', payload })
                    }}
                    disabled={checkedAttributeValuesIdsList.length !== 1}
                  >
                    <PenOutlined />
                  </AddAttributeValueButton>
                </Tooltip>
                <Tooltip title='Привязать значение атрибутов'>
                  <AddAttributeValueButton
                    data-test-id='attributeTabLinkValuesBtn'
                    onClick={() => {
                      dispatchTabAction({
                        type: 'setIsLinkAttributeValuesOpen',
                        payload: true,
                      })
                    }}
                    disabled={
                      !isCheckedProjectModuleValuesMapNotEmpty ||
                      !checkedAttributeValuesIdsList.length
                    }
                  >
                    <Chain />
                  </AddAttributeValueButton>
                </Tooltip>
                <Tooltip title='Переместить значение атрибутов'>
                  <AddAttributeValueButton
                    data-test-id='attributeTabMoveValuesBtn'
                    onClick={() => {
                      dispatchTabAction({
                        type: 'setIsMoveAttributeValuesOpen',
                        payload: true,
                      })
                    }}
                  >
                    <AddAfter />
                  </AddAttributeValueButton>
                </Tooltip>
                <Tooltip title='Удалить значение атрибутов'>
                  <AddAttributeValueButton
                    data-test-id='attributeTabDeleteValuesBtn'
                    onClick={() => {
                      dispatchTabAction({
                        type: 'setIsDeleteAttributeValuesOpen',
                        payload: true,
                      })
                    }}
                  >
                    <Trash color='#353b60' />
                  </AddAttributeValueButton>
                </Tooltip>
              </>
            )}
          </ListHeaderButtonContainer>
        </AttributeTabListHeader>
        <AttributeTabList>
          {!attributeValuesPending &&
            children.map((d, idx) => {
              let checkedChildrenLength = 0

              if (d.type === AttributeInstances.GROUP) {
                checkedChildrenLength = d.children.filter(
                  c => checkedAttributeValuesMap[c.id],
                ).length
              }

              const attributeValueCheckbox = (id: number, innerIdx: number) => (
                <Checkbox
                  data-test-id={`attributeValuesListItem_${
                    d.type === AttributeInstances.GROUP ? `${idx}_` : ''
                  }${innerIdx}_Checkbox`}
                  checked={!!checkedAttributeValuesMap?.[id]}
                  onChange={() =>
                    setCheckedAttributeValuesMap(prev => ({
                      ...prev,
                      [id]: checkedAttributeValuesMap?.[id] ? false : true,
                    }))
                  }
                />
              )

              return d.type === AttributeInstances.GROUP ? (
                <AttributeValueGroupItem
                  dataTestIdPrefix={`attributeValuesListItem_${idx}`}
                  onActionButtonClick={onListItemActionButtonClick}
                  key={d.id}
                  group={d}
                  ctxMenu={listItemCtxMenu}
                  valueToEdit={valueToEdit}
                  stopEditing={() =>
                    dispatchTabAction({ type: 'setValueToEdit', payload: null })
                  }
                  renderCheckbox={
                    <Tooltip
                      title={
                        !d.children.length
                          ? 'Группировка не должна быть пустой'
                          : ''
                      }
                    >
                      <Checkbox
                        data-test-id={`attributeValuesListItem_${idx}_Checkbox`}
                        checked={
                          !!checkedChildrenLength &&
                          d.children.length === checkedChildrenLength
                        }
                        disabled={!d.children.length}
                        onChange={() =>
                          setCheckedAttributeValuesMap(prev => ({
                            ...prev,
                            ...d.children.reduce(
                              (acc, c) => ({
                                ...acc,
                                [c.id]: checkedChildrenLength ? false : true,
                              }),
                              {},
                            ),
                          }))
                        }
                        indeterminate={
                          !!checkedChildrenLength &&
                          d.children.length !== checkedChildrenLength
                        }
                      />
                    </Tooltip>
                  }
                  renderChildCheckbox={(id, innerIdx) =>
                    attributeValueCheckbox(id, innerIdx)
                  }
                  onCreateValueInGroup={payload =>
                    dispatchTabAction({
                      type: 'setAddAttributeValuesToProjectAfterActionAction',
                      payload,
                    })
                  }
                />
              ) : (
                <AttributeValueItem
                  dataTestIdPrefix={`attributeValuesListItem_${idx}`}
                  onActionButtonClick={onListItemActionButtonClick}
                  key={d.id}
                  value={d}
                  active={checkNodeAccordance(
                    AttributeInstances.VALUE,
                    d.id,
                    listItemCtxMenu.item?.item,
                  )}
                  isEditing={checkNodeAccordance(
                    AttributeInstances.VALUE,
                    d.id,
                    valueToEdit,
                  )}
                  stopEditing={() =>
                    dispatchTabAction({ type: 'setValueToEdit', payload: null })
                  }
                  renderCheckbox={attributeValueCheckbox(d.id, idx)}
                />
              )
            })}
          {isInputOpen ? (
            <StyledAttributeInput
              dataTestIdPrefix='attributeValuesListRootLevel'
              isAttributeValue={isAttributeValue}
              inputRef={inputRef}
              onClose={() =>
                dispatchTabAction({ type: 'setIsInputOpen', payload: false })
              }
              onSubmit={
                isAttributeValue
                  ? handleAddAttributeValue
                  : handleAddAttributeValueGroup
              }
            />
          ) : null}
        </AttributeTabList>
      </AttributeTabContentBlock>
      <AttributeTabContentBlock data-test-id='attributeTabProjectList'>
        <AttributeTabListHeader>
          <ListHeaderButtonContainer>
            <ListHeaderHeading>
              <span>Проекты</span>
            </ListHeaderHeading>
            {!!checkedProjectModuleValuesMapLength && (
              <Badge>
                Значения {checkedProjectModuleValuesMapLength}
                <Cross
                  data-test-id='deselectAllProjectModuleValuesBtn'
                  onClick={() =>
                    setCheckedProjectModuleValuesMap(projectModuleValuesMap)
                  }
                />
              </Badge>
            )}
          </ListHeaderButtonContainer>
          <ListHeaderButtonContainer>
            <Tooltip title='Отвязать значение атрибутов от проектов'>
              <AddAttributeValueButton
                hide={!checkedProjectModuleValuesMapLength}
                data-test-id='attributeTabUnlinkAttributeValuesBtn'
                onClick={() => {
                  dispatchTabAction({
                    type: 'setIsUnlinkAttributeValuesOpen',
                    payload: true,
                  })
                }}
              >
                <UnlinkIcon color='#353B60' />
              </AddAttributeValueButton>
            </Tooltip>
          </ListHeaderButtonContainer>
        </AttributeTabListHeader>
        <AttributeTabList>
          {projectModuleValues.map((v, idx) => (
            <AttributeProjectItem
              dataTestIdPrefix={`projectModuleValuesListItem_${idx}`}
              key={v.urn}
              item={v}
              checkedMap={checkedProjectModuleValuesMap}
              setCheckedMap={setCheckedProjectModuleValuesMap}
              isBaseAttributeValuesChecked={
                !!checkedAttributeValuesIdsList.length
              }
            />
          ))}
        </AttributeTabList>
      </AttributeTabContentBlock>
      <TabRightSideWrapper>
        <StyledIconButton
          data-test-id='attributeTabActionsBtn'
          onClick={(e: MouseEvent) => {
            setAttributeCtxMenu({
              coords: { x: e.clientX, y: e.clientY },
              item: { item: attribute, event: e },
            })
          }}
          size='regular'
        >
          <VerticalThreeDots />
        </StyledIconButton>
        <AttributesInfo />
      </TabRightSideWrapper>
      <MoveAttributeValueModal
        onClose={(reset?: boolean) => {
          dispatchTabAction({
            type: 'setIsMoveAttributeValuesOpen',
            payload: false,
          })
          if (reset) {
            setCheckedAttributeValuesMap({})
          }
        }}
        altSubmit={payload =>
          dispatchTabAction({
            type: 'setAddAttributeValuesToProjectAfterActionAction',
            payload,
          })
        }
        isOpen={isMoveAttributeValuesOpen}
        groupList={groupList}
        checkedAttributeValuesIdsList={checkedAttributeValuesIdsList}
      />
      <LinkAttributeValuesToProjectModal
        checkedAttributeValuesIdsList={checkedAttributeValuesIdsList}
        checkedProjectModuleValuesMap={checkedProjectModuleValuesMap}
        isOpen={isLinkAttributeValuesOpen}
        onClose={(reset?: boolean) => {
          dispatchTabAction({
            type: 'setIsLinkAttributeValuesOpen',
            payload: false,
          })
          if (reset) {
            setCheckedAttributeValuesMap({})
          }
        }}
      />
      <UnlinkAttributeValuesToProjectModal
        checkedProjectModuleValuesMap={checkedProjectModuleValuesMap}
        isOpen={isUnlinkAttributeValuesOpen}
        onClose={(reset?: boolean) => {
          dispatchTabAction({
            type: 'setIsUnlinkAttributeValuesOpen',
            payload: false,
          })
        }}
      />
      {addAttributeValuesToProjectAfterActionAction ? (
        <AddAttributeValuesToProjectAfterActionModal
          checkedAttributeValuesIdsList={checkedAttributeValuesIdsList}
          data={addAttributeValuesToProjectAfterActionAction}
          onClose={() => {
            dispatchTabAction({
              type: 'setAddAttributeValuesToProjectAfterActionAction',
              payload: null,
            })
          }}
        />
      ) : null}
      <DeletePopup
        title={
          <DeletePopupTitle>{'Удалить значения атрибута'}</DeletePopupTitle>
        }
        dataTestIdPrefix='AttributeValue'
        open={isDeleteAttributeValuesOpen}
        onClose={() =>
          dispatchTabAction({
            type: 'setIsDeleteAttributeValuesOpen',
            payload: false,
          })
        }
        handleSubmit={() => {
          handleSubmitDelete(AttributeInstances.VALUE)
          setCheckedAttributeValuesMap({})
        }}
        noCross
        width={'600px'}
      >
        <Text>
          Вы уверены что хотите удалить следующие значения атрибута “
          {attribute.name}”, используемые в проектах аккаунта?
        </Text>
      </DeletePopup>
      <DeletePopup
        title={<DeletePopupTitle>{'Удалить группировку'}</DeletePopupTitle>}
        dataTestIdPrefix='AttributeGroup'
        open={Boolean(attributeGroupToDelete)}
        onClose={() =>
          dispatchTabAction({
            type: 'setAttributeGroupToDelete',
            payload: null,
          })
        }
        handleSubmit={() =>
          handleSubmitDelete(
            AttributeInstances.GROUP,
            attributeGroupToDelete?.id,
          )
        }
        noCross
        width={'600px'}
      >
        <Text>
          Вы уверены что хотите удалить группировку значений атрибутов{' '}
          <span>“{attributeGroupToDelete?.name}”</span>?
        </Text>
      </DeletePopup>
      <DeletePopup
        title={<DeletePopupTitle>{'Удалить атрибут'}</DeletePopupTitle>}
        dataTestIdPrefix='Attribute'
        open={isDeleteAttributeOpen}
        onClose={() =>
          dispatchTabAction({
            type: 'setIsDeleteAttributeOpen',
            payload: false,
          })
        }
        handleSubmit={() => {
          handleSubmitDelete(AttributeInstances.ATTRIBUTE, attributeId)
          setSelectedAttribute(null)
        }}
        noCross
        width={'500px'}
      >
        <Text>
          Атрибут <span>“{name}”</span>, используемый в проектах аккаунта, вы
          уверены что хотите удалить? <br />
          После подтверждения, это действие не может быть отменено.
        </Text>
      </DeletePopup>
      <EditOrAddAttributeModal
        open={isEditAttributeOpen}
        onClose={() =>
          dispatchTabAction({ type: 'setIsEditAttributeOpen', payload: false })
        }
        attribute={attribute}
        setSelected={setSelectedAttribute}
      />
    </AttributeTabTabWrapper>
  )
}
